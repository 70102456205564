import * as React from "react";
import styled from "styled-components";
import { theme } from "./colors";

const PostTitleWrapper = styled.div`
  padding: 3.2rem;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  text-align: center;
`;
const PostTitleSub = styled.div`
  font-size: 1rem;
  color: ${theme.text};
  margin-bottom: 0.4rem;
  @media (max-width: 800px) {
    font-size: 0.8rem;
  }
`;
const PostTitleCategory = styled.span`
  color: ${theme.action};
`;
const PostTitleContent = styled.div`
  color: ${theme.text};
  font-size: 3.2rem;
  padding-bottom: 1.2rem;
  @media (max-width: 800px) {
    font-size: 2.4rem;
  }
  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;
const PostTitle = ({ date, category, title }) => {
  return (
    <PostTitleWrapper>
      {date && (
        <PostTitleSub>
          {date} | <PostTitleCategory>{category}</PostTitleCategory>
        </PostTitleSub>
      )}
      <PostTitleContent>{title}</PostTitleContent>
    </PostTitleWrapper>
  );
};

export default PostTitle;
